/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        WebFontConfig = {
          google: { families: [ 'Open+Sans:400,700:latin-ext' ] }
        };
        (function() {
          var wf = document.createElement('script');
          wf.src = '//ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';
          wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();

        $.fn.extend({
          animateCss: function (animationName) {
            var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
            $(this).addClass('animated ' + animationName).one(animationEnd, function() {
              $(this).removeClass(animationName);
            });
          }
        });

        $('.left-side').addClass('animated fadeIn');
        setTimeout(function() {
            $('.right-side').addClass('animated fadeIn');
        }, 300);

        var hover_timer = [];
        $('.left-side, .right-side').on({
          mouseenter: function (e) {
            var idx = $(e.currentTarget).index();

            clearTimeout(hover_timer[idx]);
            $('.short-descr', $(this)).addClass('animated fadeInDown');
            //$('.moreinfo-btn-cont', $(this)).addClass('animated bounceInLeft');
          },
          mouseleave: function (e) {
            var $that = $(this),
                idx = $(e.currentTarget).index();

            hover_timer[idx] = setTimeout(function() {
              $('.short-descr', $that).removeClass('fadeInDown').animateCss('fadeOutUp');
              //$('.moreinfo-btn-cont', $that).removeClass('bounceInLeft').animateCss('slideOutLeft');
            }, 250);
          }
        });

        $('.moreinfo-btn.minfo').click(function(e) {
          e.preventDefault();

          if ( ! $('.more-info-cont').hasClass('animated') ) {
            $('.content-info').addClass('static');

            $('.more-info-cont').addClass('animated');
            $('.more-info').addClass('animated slideInDown');

          } else {
            $('.more-info').removeClass('slideInDown').animateCss('slideOutUp');
            $('.content-info').removeClass('static');

            setTimeout(function() {
              $('.more-info-cont').removeClass('animated');
            }, 1000);
          }
        });

        $('.close-btn a').click(function(e) {
          e.preventDefault();

          $('.more-info').removeClass('slideInDown').animateCss('slideOutUp');

          setTimeout(function() {
            $('.more-info-cont').removeClass('animated');
          }, 1000);
        });

        /*var footer_static = function () {
          if ( $('.content-info').offset().top <= $('.row.moreinfo-btn-cont').offset().top + $('.row.moreinfo-btn-cont').height() + 35 ) {
            $('.content-info').addClass('static');
          } else {
            $('.content-info').removeClass('static');
          }
        };

        footer_static();
        $(window).resize(function() {
          setTimeout(footer_static, 250);
        });*/

        $('.add_to_cart_button').click(function () {
          var $that = $(this);
          var timer = setInterval(function() {
            if ( $that.hasClass('added') ) {
              $.post(
                  woocommerce_params.ajax_url,
                  {'action': 'mode_theme_update_mini_cart'},
                  function(response) {
                    $('#mini-cart').html(response);
                  }
              );

              clearInterval(timer);
            }
          }, 250);
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'page_template_template_map': {
      init: function() {
        function add_marker( $marker, map ) {
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

          var marker = new google.maps.Marker({
            position  : latlng,
            map     : map
          });

          map.markers.push( marker );

          if( $marker.html() )
          {
            var infowindow = new google.maps.InfoWindow({
              content   : $marker.html()
            });

            google.maps.event.addListener(marker, 'click', function() {

              infowindow.open( map, marker );

            });
          }
        }

        function center_map( map ) {
          var bounds = new google.maps.LatLngBounds();

          $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

            bounds.extend( latlng );

          });

          if( map.markers.length === 1 )
          {
              map.setCenter( bounds.getCenter() );
              map.setZoom( 14 );
          }
          else
          {
            map.fitBounds( bounds );
          }
        }

        function render_map( $el ) {
          var $markers = $el.find('.marker');

          var args = {
            zoom    : 14,
            center    : new google.maps.LatLng(0, 0),
            mapTypeId : google.maps.MapTypeId.ROADMAP
          };

          var map = new google.maps.Map( $el[0], args);

          map.markers = [];

          $markers.each(function(){
            add_marker( $(this), map );
          });

          center_map( map );
        }
          
        $('.actual-map').each(function(){
          render_map( $(this) );
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
